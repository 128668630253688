import React, { useState, useEffect, useCallback } from 'react';
import './FeedNews.css';

const FeedNews = () => {
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const apiToken = process.env.REACT_APP_GNEWS_API_KEY
    const apiUrl = "https://gnews.io/api/v4/top-headlines";

    const fetchNews = useCallback(async () => {
        setLoading(true);
        setError(null); // Reseta erros em novas tentativas
        try {
            const response = await fetch(
                `${apiUrl}?category=business&apikey=${apiToken}&lang=pt&country=br&max=40&sortby=publishedAt`,
                {
                    method: 'GET',
                }
            );
    
            if (!response.ok) {
                throw new Error('Erro na resposta da API');
            }
    
            const data = await response.json();
            if (data.articles && data.articles.length > 0) {
                setNews(data.articles);
            } else {
                throw new Error('Nenhuma notícia encontrada');
            }
        } catch (err) {
            setError('Erro ao carregar notícias. Por favor, tente novamente mais tarde.');
        } finally {
            setLoading(false);
        }
    }, [apiToken, apiUrl]);  // Certifique-se de incluir as variáveis que a função depende
    
    useEffect(() => {
        fetchNews();
    
        const intervalId = setInterval(() => {
            fetchNews();
        }, 30 * 60 * 1000); // Atualiza a cada 15 minutos
    
        return () => clearInterval(intervalId);
    }, [fetchNews]);  // Agora fetchNews é memorizada com useCallback
    

    if (loading) return <p className="loading-message">Carregando notícias...</p>;
    if (error) return <p className="error-message">{error}</p>;

    return (
        <div className="feed-carousel">
            <h2>Últimas Notícias</h2>
            <div className="carousel">
                {news.map((article, index) => (
                    <div key={article.url || index} className="news-item">
                        <a href={article.url} target="_blank" rel="noopener noreferrer">
                            <img
                                src={article.image}
                                alt={article.title}
                                className="news-image"
                            />
                            <h3 className="news-title">{article.title}</h3>
                            <p className="news-description">{article.description}</p>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeedNews;