import React, { useEffect } from 'react';
import './Header.css';

const Header = () => {
  useEffect(() => {
    // Inicializa os anúncios do AdSense
    if (window.adsbygoogle && typeof window.adsbygoogle.push === "function") {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <header className="header">
      <h1>Cotações de Moedas</h1>
      <div className="ads-space">
        <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-5448569853805015"
            data-ad-slot="3145189077"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
      </div>
    </header>
  );
};

export default Header;
