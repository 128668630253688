import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import FinancialMarket from './pages/FinancialMarket';
import './styles/App.css';

const App = () => (
  <Router>
    <div className="app">
      <Header />
      <div className="content-wrapper">
        <div className="sidebar"> {/* Lado esquerdo */}
          <div className="ads-space"></div>
        </div>
        <div className="main-content"> {/* Conteúdo central */}
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/financial-market" element={<FinancialMarket />} />
          </Routes>
        </div>
        <div className="sidebar"> {/* Lado direito */}
          <div className="ads-space"></div>
        </div>
      </div>
    </div>
  </Router>
);

export default App;
