import React from 'react';
import { Link } from 'react-router-dom'; // Importe o Link do react-router-dom
import './TermsOfUse.css'; // Importe o arquivo CSS

const TermsOfUse = () => {
  return (
    <div className="terms-of-use-container">
      <h1>Termos de Uso</h1>
      <p>
        Bem-vindo ao nosso site. Ao acessar e utilizar este site, você concorda com os seguintes termos e condições:
      </p>
      <h2>1. Aceitação dos Termos</h2>
      <p>
        Ao utilizar este site, você concorda em cumprir e estar vinculado aos termos e condições estabelecidos neste documento.
      </p>
      <h2>2. Uso do Site</h2>
      <p>
        Você concorda em utilizar o site apenas para fins legais e de acordo com todas as leis e regulamentos aplicáveis.
      </p>
      <h2>3. Propriedade Intelectual</h2>
      <p>
        Todo o conteúdo presente neste site, incluindo textos, imagens, gráficos e logotipos, são de propriedade exclusiva da empresa e estão protegidos por direitos autorais.
      </p>
      <h2>4. Limitação de Responsabilidade</h2>
      <p>
        A empresa não se responsabiliza por danos diretos, indiretos, incidentais ou consequenciais resultantes do uso ou da incapacidade de uso deste site.
      </p>
      <h2>5. Alterações nos Termos</h2>
      <p>
        Reservamo-nos o direito de modificar estes termos a qualquer momento. Recomenda-se revisar esta página periodicamente para estar ciente de quaisquer alterações.
      </p>
      <h2>6. Contato</h2>
      <p>
        Se você tiver dúvidas sobre estes Termos de Uso, entre em contato conosco através do nosso e-mail.
      </p>
      {/* Link para voltar à página inicial */}
      <Link to="/" className="back-to-home-link">Home</Link>
    </div>
  );
};

export default TermsOfUse;