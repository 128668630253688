import './Home.css';
import React, { useEffect, useState } from 'react';
import CotacaoCard from '../components/CotacaoCard';
import FeedNews from '../components/FeedNews';
import { fetchCotacoes2 } from '../api/cotacaoApi';
import { Link } from 'react-router-dom';

const Home = () => {
  const [cotacoes, setCotacoes] = useState(null);
  const [error, setError] = useState(null);

  // Fetch das cotações com cache
  useEffect(() => {
    const fetchData = async () => {
      // Tentar carregar as cotações do localStorage (cache)

      const cachedCotacoes = localStorage.getItem('cotacoes');
      const lastFetched = localStorage.getItem('lastFetched');
      const isCacheValid = lastFetched && (Date.now() - parseInt(lastFetched, 10) < 15 * 60 * 1000);

      if (cachedCotacoes && isCacheValid) {
          setCotacoes(JSON.parse(cachedCotacoes));
      }

      try {
        // Fazer requisição para atualizar as cotações
        const response = await fetchCotacoes2();
        setCotacoes(response.data);
        // Salvar as cotações no localStorage para uso futuro
        localStorage.setItem('cotacoes', JSON.stringify(response.data));
        localStorage.setItem('lastFetched', Date.now().toString()); // Atualiza o timestamp de quando a requisição foi feita
      } catch (err) {
        setError('Erro ao buscar cotações');
      }
    };

    fetchData(); // Chama a função para buscar as cotações

    // Atualizar a cada 15 minutos (900000ms)
    const intervalId = setInterval(fetchData, 15 * 60 * 1000);

    // Limpar o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, []);

  // Tratamento de erro ou carregamento
  if (!cotacoes && !error) {
    return <div className="loading-spinner">Carregando...</div>;
  }

  return (
    <div className="home-container">
      <h1>Bem-vindo!</h1>
      <p>
        Aqui você encontra as cotações mais recentes de moedas em relação ao Real Brasileiro.
      </p>
      {/* Links para os Termos de Uso e Política de Privacidade */}
      <div className="links">
        <Link to="/terms-of-use" className="terms-of-use-link" aria-label="Leia os Termos de Uso">
          Termos de Uso
        </Link>
        <span> | </span>
        <Link to="/privacy-policy" className="privacy-policy-link" aria-label="Leia as políticas de privacidade">
          Política de Privacidade
        </Link>
      </div>
      <div className="home">
        <aside className="ads-column"></aside>

        <main className="content">
          {/* Seção de Cotações */}
          <div className="cards">
            {Object.values(cotacoes).map((moeda) => (
              <CotacaoCard
                key={moeda.code}
                cod={moeda.code}
                titulo={moeda.name}
                nome={moeda.name}
                atual={parseFloat(moeda.bid)}
                variacao={parseFloat(moeda.varBid)}
                porcentagem={parseFloat(moeda.pctChange)}
              />
            ))}
          </div>

          {/* Seção Informativa */}
          <section className="info-section">
            <h2>O Mercado de Câmbio</h2>
            <p>
              O mercado de câmbio, ou Forex, conecta economias globais,
              permitindo a conversão entre moedas. Fatores como inflação, taxas
              de juros e estabilidade política influenciam as taxas de câmbio.
            </p>
          </section>

          {/* Outras Seções */}
          <section className="info-section">
            <h2>O Real Brasileiro</h2>
            <p>
              O real é a moeda oficial do Brasil desde 1994, e seu valor em relação a
              moedas como o dólar e o euro reflete a saúde econômica do país. Flutuações no câmbio impactam exportações, importações e
              o custo de vida.
            </p>
          </section>
          <section className="info-section">
            <h2>O Mercado de Câmbio e o Sistema Financeiro Global</h2>
            <p>
              O mercado de câmbio, conhecido como Forex (Foreign Exchange Market), é um dos maiores e mais dinâmicos mercados financeiros do mundo. Ele desempenha um papel crucial na economia global ao facilitar a conversão de moedas entre países, permitindo que empresas, governos e indivíduos realizem transações internacionais de forma eficiente.
            </p>
            <p>
              As taxas de câmbio são influenciadas por diversos fatores econômicos e políticos, incluindo taxas de juros, níveis de inflação, políticas monetárias, estabilidade política, e até mesmo eventos geopolíticos. Essas variações impactam diretamente as exportações e importações, o investimento estrangeiro e a competitividade das economias.
            </p>
            <p>
              No Brasil, o mercado de câmbio é regulamentado pelo Banco Central, que tem como objetivo manter a estabilidade econômica, controlar a inflação e administrar as reservas internacionais do país. Movimentos significativos nas taxas de câmbio podem ter impactos profundos na economia brasileira, afetando desde o custo de produtos importados até o preço de commodities exportadas.
            </p>
            <p>
              Além do mercado de câmbio, o sistema financeiro global engloba outros mercados, como o de ações, de títulos e de commodities, que interagem de maneira integrada. Investidores frequentemente analisam o comportamento do câmbio em conjunto com esses mercados para tomar decisões estratégicas. Por exemplo, uma valorização ou desvalorização significativa do real em relação ao dólar pode alterar os fluxos de investimento em setores como agronegócio, tecnologia e indústria.
            </p>
            <p>
              Para aqueles interessados em aprender mais ou investir no mercado financeiro, é importante entender conceitos básicos, como hedging, especulação e arbitragem, que ajudam a mitigar riscos e aproveitar oportunidades. Ter uma boa compreensão do mercado financeiro pode ser uma vantagem estratégica, tanto para empresas quanto para investidores individuais.
            </p>
          </section>
          <section className="info-section">
            <h2>Como Funciona a Negociação de Câmbio e Contratos Futuros</h2>
            <p>
              A negociação de câmbio (Forex) é o processo de troca de uma moeda por outra, geralmente realizado por empresas, investidores e instituições financeiras. O mercado de câmbio é descentralizado, funcionando 24 horas por dia durante a semana, com transações realizadas globalmente entre diferentes fusos horários.
            </p>
            <p>
              No Brasil, além do mercado à vista, onde a moeda é comprada ou vendida de forma imediata, existe um mercado futuro bem estruturado para moedas, operado na <strong>B3</strong> (Bolsa de Valores Brasileira). Este mercado é especialmente relevante para investidores, empresas e exportadores/importadores que desejam se proteger das flutuações cambiais.
            </p>
            
            <h3>Contratos Futuros de Dólar</h3>
            <p>
              Os contratos futuros de dólar são acordos negociados na bolsa em que duas partes se comprometem a comprar ou vender uma determinada quantidade de dólares a um preço fixado, em uma data futura. Este mercado é utilizado para:
            </p>
            <ul>
              <li><strong>Proteção (Hedge):</strong> Empresas que realizam negócios internacionais podem usar contratos futuros para se proteger contra oscilações na taxa de câmbio.</li>
              <li><strong>Especulação:</strong> Investidores apostam na alta ou baixa do dólar, buscando lucro com as variações nos preços dos contratos.</li>
            </ul>
            <p>
              Esses contratos são padronizados, sendo que o mais comum na B3 é o contrato de <strong>Dólar Cheio</strong>, que representa um lote de 50 mil dólares. Ele é amplamente utilizado por grandes investidores institucionais e empresas.
            </p>

            <h3>Mini Dólar</h3>
            <p>
              Para atender investidores menores, existem os contratos de <strong>Mini Dólar</strong>, que possuem um tamanho menor, representando lotes de 10 mil dólares. Isso permite que investidores individuais tenham acesso ao mercado futuro com menor custo e alavancagem.
            </p>

            <h3>Como Funciona na Prática</h3>
            <p>
              As negociações de contratos futuros de dólar ocorrem de forma eletrônica na B3. Os preços desses contratos são determinados pela oferta e demanda, além de fatores econômicos, como:
            </p>
            <ul>
              <li>Taxas de juros nos EUA e no Brasil;</li>
              <li>Dados econômicos, como PIB, inflação e balança comercial;</li>
              <li>Eventos políticos e geopolíticos;</li>
              <li>Expectativas de mercado.</li>
            </ul>
            <p>
              No final do período do contrato, os ajustes são realizados diariamente com base na variação do preço do dólar futuro, o que é conhecido como ajuste diário. Isso protege tanto compradores quanto vendedores de grandes perdas, já que as margens são ajustadas gradualmente.
            </p>

            <h3>Vantagens e Riscos</h3>
            <p>
              Negociar contratos futuros de dólar oferece diversas vantagens, como:
            </p>
            <ul>
              <li><strong>Proteção contra a volatilidade:</strong> Empresas e investidores podem garantir um preço fixo para o dólar no futuro.</li>
              <li><strong>Alavancagem:</strong> É possível negociar contratos de alto valor com um capital inicial relativamente pequeno.</li>
              <li><strong>Liquidez:</strong> O mercado futuro de dólar na B3 é um dos mais líquidos do Brasil.</li>
            </ul>
            <p>
              No entanto, também existem riscos, como:
            </p>
            <ul>
              <li>Alavancagem elevada pode amplificar perdas;</li>
              <li>Oscilações inesperadas no câmbio podem afetar especuladores negativamente;</li>
              <li>Complexidade para iniciantes, que devem entender bem o mercado antes de operar.</li>
            </ul>

            <p>
              Entender o funcionamento do mercado de câmbio e os contratos futuros é essencial para quem deseja operar nesse segmento, seja para proteção ou para especulação. Com a combinação de estratégia, estudo e ferramentas adequadas, é possível tirar proveito das oportunidades que esses mercados oferecem.
            </p>
          </section>
          <section className="info-section">
            <h2>Estudo: A Chave para o Sucesso no Mercado de Câmbio</h2>
            <p>
              O mercado de câmbio e os contratos futuros, como o dólar futuro, oferecem grandes oportunidades, mas também apresentam riscos consideráveis. Antes de se aventurar nesse universo, é fundamental dedicar tempo ao estudo e à compreensão de como esses mercados funcionam.
            </p>
            <p>
              Negociar moedas e contratos futuros não é apenas uma questão de sorte ou intuição. Esse é um ambiente complexo, influenciado por fatores econômicos, políticos e globais que exigem preparação e estratégia. Sem o devido conhecimento, há um alto risco de perdas financeiras, especialmente devido à alavancagem que amplifica os ganhos, mas também os prejuízos.
            </p>
            <p>
              Portanto, antes de dar o primeiro passo, recomenda-se:
            </p>
            <ul>
              <li>Entender os conceitos básicos de câmbio, contratos futuros e mini contratos;</li>
              <li>Estudar os fatores que impactam as taxas de câmbio, como inflação, juros e políticas monetárias;</li>
              <li>Praticar em simuladores de mercado antes de investir capital real;</li>
              <li>Manter-se atualizado com as notícias econômicas e globais;</li>
              <li>Elaborar estratégias de gestão de risco para proteger seu capital.</li>
            </ul>
            <p>
              Além disso, é importante buscar orientação de especialistas ou profissionais qualificados para ajudar na construção de um plano sólido e bem-informado. O aprendizado contínuo e a prática consistente são essenciais para alcançar o sucesso nesse mercado.
            </p>
            <p>
              Lembre-se: o mercado de câmbio não é um jogo. É um ambiente altamente competitivo, onde os mais preparados se destacam. Invista em conhecimento antes de investir seu dinheiro.
            </p>
          </section>

          <section className="info-section">
            {/* Carrossel de Notícias */}
            <FeedNews />
          </section>

          <Link to="/financial-market" className="financial-market-link">
              Entenda o Mercado Financeiro
          </Link>

          <section className="info-section blog-suggestion">
          <h2>
            <a 
              href="https://viajandocomigo.com" 
              target="_blank" 
              rel="noopener noreferrer"
              className="blog-link"
              aria-label="Visite o blog Viajando Comigo"
            >
              <span className="highlight">Viajando Comigo</span>
            </a>
          </h2>
            <p>
              🌊 No blog <a 
                href="https://viajandocomigo.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="blog-link"
                aria-label="Visite o blog Viajando Comigo para dicas de viagem"
              >
                Viajando Comigo
              </a>, você encontra dicas sobre destinos incríveis como a Praia do Preá no Ceará, com suas lagoas e buracos azuis 🌅, e o encantador Icapuí-CE, um lugar perfeito para quem busca tranquilidade e beleza natural.
            </p>
            <p>
              🌴 Veja mais detalhes e dicas de lindos lugares no Brasil para sua próxima viagem!
            </p>
            <p>
              Acesse o blog para mais detalhes e comece a planejar sua próxima aventura com informações atualizadas e inspiradoras!
            </p>
          </section>

        </main>
        <aside className="ads-column"></aside>
      </div>
    </div>
  );
};

export default Home;