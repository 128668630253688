import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.exchangerate-api.com/v4/',
});

export const fetchCotacoes = async () => {
  try {
    const response = await  api.get('latest/BRL');;
    return response.data.rates;
  } catch (error) {
    console.error('Erro ao buscar cotações:', error);
    throw error;
  }
};

export const fetchCotacoes2 = async () => {
  try {
    const response = await axios.get(
      'https://economia.awesomeapi.com.br/json/last/USD-BRL,EUR-BRL,GBP-BRL,BTC-BRL'
    );
    
    return response;
  } catch (err) {
      return err.message;
  }
};
