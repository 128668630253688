import React from 'react';
import { Link } from 'react-router-dom'; // Caso use React Router
import './FinancialMarket.css';

const FinancialMarket = () => {
  return (
    <div className="financial-market-container">
      <nav className="navigation">
        <Link to="/" className="home-link">Voltar para a Home</Link>
      </nav>

      <header className="header">
        <h1>Descubra o Mercado Financeiro</h1>
      </header>

      <section className="introduction">
        <p>
          Você sabia que participa do mercado financeiro todos os dias, mesmo sem perceber? Seja pela sua conta bancária ou nas compras do dia a dia, o mercado financeiro está presente na vida de todos. E não, ele não é só para especialistas ou pessoas com muito dinheiro. Vamos descomplicar isso para você!
        </p>
      </section>

      <section className="what-is">
        <h2>O que é Mercado Financeiro?</h2>
        <p>
          É um espaço onde pessoas e empresas negociam dinheiro, bens e investimentos. Aqui entram bancos, corretoras, e até mesmo as compras do dia a dia. É como uma grande feira, onde o que está sendo negociado são ativos, como dinheiro, ações ou empréstimos.
        </p>
      </section>

      <section className="participants">
        <h2>Quem Participa?</h2>
        <ul>
          <li>
            <strong>Investidores:</strong> Pessoas que aplicam dinheiro para ter retorno, como quem investe na poupança ou compra ações.
          </li>
          <li>
            <strong>Tomadores:</strong> Aqueles que pegam dinheiro emprestado para realizar projetos ou pagar despesas.
          </li>
          <li>
            <strong>Instituições:</strong> Bancos e corretoras que conectam quem quer investir com quem precisa de dinheiro.
          </li>
        </ul>
      </section>

      <section className="regulators">
        <h2>Quem Cuida do Mercado?</h2>
        <p>
          Para garantir que tudo funcione bem e de forma justa, existem órgãos que fiscalizam as operações, como:
        </p>
        <ul>
          <li>
            <strong>Banco Central:</strong> Regula o sistema financeiro e combate a inflação.
          </li>
          <li>
            <strong>CVM:</strong> Protege investidores e garante boas práticas.
          </li>
          <li>
            <strong>CMN:</strong> Define as regras do mercado financeiro.
          </li>
        </ul>
      </section>

      <section className="categories">
        <h2>Divisões do Mercado</h2>
        <p>O mercado financeiro tem várias áreas, como:</p>
        <ul>
          <li>
            <strong>Mercado de Câmbio:</strong> Compra e venda de moedas estrangeiras.
          </li>
          <li>
            <strong>Mercado Monetário:</strong> Empréstimos de curto prazo.
          </li>
          <li>
            <strong>Mercado de Crédito:</strong> Empréstimos para pessoas e empresas.
          </li>
          <li>
            <strong>Mercado de Capitais:</strong> Investimentos em ações e outros ativos.
          </li>
        </ul>
      </section>

      <footer className="cta-section">
        <h2>Pronto para Aprender Mais?</h2>
        <p>
          Entenda mais sobre o assunto e acompanhe as cotações das principais moedas do mercado!
        </p>
        <Link to="/" className="cta-link">Saiba Mais</Link>
      </footer>
    </div>
  );
};

export default FinancialMarket;