import React from 'react';
import { Link } from 'react-router-dom';  // Importe o Link do react-router-dom
import './PrivacyPolicy.css';  // Importe o arquivo CSS

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Política de Privacidade</h1>
      <p>
        Nossa Política de Privacidade descreve como coletamos, usamos e
        protegemos suas informações quando você visita nosso site. Ao utilizar
        nossos serviços, você concorda com nossa política.
      </p>
      <h2>Coleta de Dados</h2>
      <p>
        Coletamos informações de navegação para personalizar a experiência e
        fornecer anúncios relevantes. Essas informações podem incluir seu IP,
        dados de cookies e dados de navegação.
      </p>
      <h2>Cookies</h2>
      <p>
        Utilizamos cookies para personalizar o conteúdo e os anúncios, oferecer
        recursos de mídia social e analisar nosso tráfego.
      </p>
      <h2>Segurança</h2>
      <p>
        Seus dados são protegidos com criptografia e protocolos de segurança
        avançados. No entanto, nenhuma transmissão de dados pela internet é 100%
        segura.
      </p>
      <p>
        Se você tiver dúvidas, entre em contato conosco através do nosso e-mail.
      </p>
      
      {/* Link para voltar à página inicial */}
      <Link to="/" className="back-to-home-link">Home</Link>
    </div>
  );
};

export default PrivacyPolicy;